import { FC, forwardRef, HTMLProps, ReactNode } from 'react'
import clsx from 'clsx'

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  variant?: 'purple' | 'orange'
  type?: 'button' | 'submit' | 'reset'
  children: ReactNode
  isDisabled?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'purple', children, type = 'button', isDisabled, ...props }, ref) => {
    const isPurple = variant === 'purple'

    return (
      <button
        ref={ref}
        className='focus:not:focus-visible:outline-none group relative border-none bg-transparent p-0 cursor-pointer outline-offset-4 transition-[filter] duration-250 hover:brightness-110'
        type={type}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        {...props}
      >
        <span className='group-active:transform group-active:duration-34 group-active:translate-y-[1px] group-hover:transform group-hover:duration-250 group-hover:ease-[cubic-bezier(.3, .7, .4, 1.5)] group-hover:translate-y-1 ease-[cubic-bezier(0.3, 0.7, 0.4, 1)] bg-btn-shadow absolute top-0 left-0 w-full h-full rounded-xl duration-600 will-change-transform transform translate-y-0.5' />
        <span
          style={{
            background: isPurple
              ? 'linear-gradient(to left, hsla(238, 82%, 45%, 1) 0%,hsl(238, 82%, 65%) 8%,hsl(238, 82%, 65%) 92%,hsla(238, 82%, 45%, 1) 100%)'
              : 'linear-gradient(to left, hsl(22, 100%, 50%) 0%,hsl(29, 100%, 50%) 8%,hsl(29, 100%, 50%) 92%,hsl(22, 100%, 50%) 100%)',
          }}
          className='absolute top-0 left-0 w-full h-full rounded-xl'
        />
        <span
          className={clsx(
            isPurple ? 'bg-btn-primary' : 'bg-btn-secondary',
            'group-active:transform group-active:duration-34 group-active:-translate-y-0.5 group-hover:transform group-hover:duration-250 group-hover:ease-[cubic-bezier(.3, .7, .4, 1.5)] group-hover:-translate-y-1.5 ease-[cubic-bezier(.3, .7, .4, 1)] relative block px-10 py-3 text-xl text-white transition -translate-y-1 bg-btn-primary duration-600 rounded-xl will-change-transform'
          )}
        >
          {children}
        </span>
      </button>
    )
  }
)

Button.displayName = 'Button'
