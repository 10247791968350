import { FC, useMemo } from 'react'
import { PortableText } from '@portabletext/react'

interface ProseableTextProps {
  blocks: any[]
}

export const ProseableText: FC<ProseableTextProps> = ({ blocks = [] }) => {
  // Group together standard `_type === "block"`  blocks
  // eg <p>, <li>, etc – and separate out everyone else
  const blockGroups = useMemo(
    () =>
      blocks.reduce(
        (acc, item) => {
          const lastIdx = acc.length - 1

          if (
            // We don't have items in this group yet
            acc[lastIdx].length === 0 ||
            // The last group has the same `type`
            acc[lastIdx][0]._type === item._type
          ) {
            acc[lastIdx].push(item)
          } else {
            // Time to create a new group, because the `type` is different compared to last group
            acc.push([item])
          }

          return acc
        },
        [[]]
      ),
    [blocks]
  )

  if (!blockGroups?.length) return null

  return blockGroups.map((group: any) =>
    group[0]._type === 'block' ? (
      <div key={group[0]._key} className='prose max-w-none'>
        <PortableText value={group} />
      </div>
    ) : (
      <PortableText key={group[0]._key} value={group} />
    )
  )
}
