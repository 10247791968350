import { FC, ReactNode } from 'react'
import Head from 'next/head'

interface AuthLayoutProps {
  title: string
  description: string
  className?: string
  children: ReactNode
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  title,
  description,
  className,
  children,
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
      </Head>
      <main className={className}>{children}</main>
    </>
  )
}
