import { FC } from 'react'
import { signOut } from 'next-auth/react'
import type { Session } from 'next-auth'
import Link from 'next/link'
import Image from 'next/image'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

interface AppHeaderProps {
  user: Session['user']
}

export const AppHeader: FC<AppHeaderProps> = ({ user }) => {
  return (
    <header className='flex items-center justify-between py-8'>
      <Link href='/' passHref>
        <a>
          <Image src='/logo.svg' height={40} width={118.39} title='Hypzz' alt='logo' />
        </a>
      </Link>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Image
            height={42}
            width={42}
            src={user?.image || ''}
            className='z-10 rounded-full'
            title='Avatar'
            alt='avatar'
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          align='end'
          className='py-2 bg-[#FDFAFF] rounded-md border border-navy-900 w-48'
        >
          <DropdownMenu.Arrow offset={16} />
          <DropdownMenu.Item
            className='px-4 py-1 cursor-pointer select-none text-navy-900 hover:bg-purple-100'
            onSelect={() => signOut({ callbackUrl: '/' })}
          >
            Logout
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </header>
  )
}
