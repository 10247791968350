import { FC, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import Image, { type StaticImageData } from 'next/image'
import { MotionInView } from 'components'
import Folder from 'public/img/folder.png'
import Presentation from 'public/img/presentation.png'
import Trophy from 'public/img/trophy.png'
import Wallet from 'public/img/wallet.png'

export const Expectations: FC = () => {
  const { t } = useTranslation('index')

  const features = useMemo<CardProps[]>(
    () => [
      { image: { src: Wallet, alt: 'wallet' }, title: t('automated-payouts') },
      {
        image: { src: Trophy, alt: 'trophy' },
        title: t('account-verified'),
      },
      {
        image: { src: Presentation, alt: 'presentation' },
        title: t('ai-tools'),
      },
      {
        image: { src: Folder, alt: 'folder' },
        title: t('no-strings-attached'),
      },
    ],
    [t]
  )

  return (
    <div className='bg-[#FDFAFF] py-28 2xl:px-4'>
      <div className='container grid w-auto grid-cols-4 mx-4 auto-rows-auto sm:mx-auto md:grid-cols-12 gap-x-6'>
        <div className='text-center col-span-full mb-28'>
          <MotionInView>
            <h2 className='text-5xl font-semibold leading-[1.3] text-navy-900'>
              {t('expectations-title-1')}{' '}
              <span className='relative inline-block mx-3 before:block before:absolute before:-inset-x-1 before:inset-y-1 before:bg-highlight-green'>
                <span className='relative'>{t('expectations-title-2')}</span>
              </span>
            </h2>
          </MotionInView>
        </div>
        <div className='grid h-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 col-span-full gap-x-6 gap-y-6 lg:gap-y-0'>
          {features.map((feature, i) => (
            <Card key={i} {...feature} />
          ))}
        </div>
      </div>
    </div>
  )
}

interface CardProps {
  title: string
  image: { src: StaticImageData; alt: string }
}

const Card: FC<CardProps> = ({ image, title }) => {
  return (
    <div className='relative flex-1 cursor-default group'>
      <span
        style={{
          background:
            'linear-gradient(to left, #e3c2ff 0%,#EBD3FF 8%,#EBD3FF 92%,#e3c2ff 100%)',
        }}
        className='absolute inset-x-0 -bottom-3 rounded-[2rem] h-full shadow'
      />
      <div className='bg-[#F3E5FF] rounded-[2rem] items-center flex flex-col px-8 relative h-full group-hover:-translate-y-2 group-hover:transform group-hover:duration-250 group-hover:ease-[cubic-bezier(.3, .7, .4, 1.5)]'>
        <div className='flex-1 w-full pt-8'>
          <Image
            src={image.src}
            alt={image.alt}
            height='100%'
            width='100%'
            objectFit='contain'
            layout='responsive'
            draggable={false}
          />
        </div>
        <div className='flex items-center flex-1 pt-6 pb-16 lg:pt-10 align-center'>
          <p className='text-xl font-medium text-center text-navy-900'>{title}</p>
        </div>
      </div>
    </div>
  )
}
