import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { MotionInView } from 'components'
import TikTok from 'public/img/platforms/TikTok.jpg'
import Twitter from 'public/img/platforms/Twitter.jpg'
import Snapchat from 'public/img/platforms/Snapchat.jpg'
import Instagram from 'public/img/platforms/Instagram.jpg'
import Discord from 'public/img/platforms/Discord.jpg'
import Youtube from 'public/img/platforms/Youtube.jpg'
import Facebook from 'public/img/platforms/Facebook.jpg'

export const Platforms: FC = () => {
  const { t } = useTranslation('index')

  return (
    <div className='bg-white py-28'>
      <div className='container grid w-auto grid-cols-3 grid-rows-2 mx-4 sm:mx-auto md:grid-rows-1 md:grid-cols-12 gap-x-6 gap-y-6 md:gap-y-6'>
        <div className='row-start-2 space-y-8 md:row-auto col-span-full md:col-span-6 xl:col-span-4 xl:col-start-3 place-self-center'>
          <MotionInView>
            <h2 className='text-5xl font-semibold leading-tight text-navy-900'>
              {t('platforms-title')}
            </h2>
          </MotionInView>
          <MotionInView>
            <p className='font-medium text-gray-500'>{t('platforms-text')}</p>
          </MotionInView>
        </div>
        <div className='flex flex-col items-end justify-center col-span-1 row-start-1 space-y-6 md:col-span-2 md:row-auto xl:items-center xl:col-span-1 xl:col-start-8'>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={TikTok}
              alt='TikTok logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={Twitter}
              alt='Twitter logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center col-span-1 row-start-1 space-y-6 md:col-span-2 md:row-auto xl:col-span-1 xl:col-start-9'>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={Snapchat}
              alt='Snapchat logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={Instagram}
              alt='Instagram logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={Discord}
              alt='Discord logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
        </div>
        <div className='flex flex-col items-start justify-center col-span-1 row-start-1 space-y-6 md:col-span-2 md:row-auto xl:items-center xl:col-span-1 xl:col-start-10'>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={Youtube}
              alt='YouTube logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
          <div className='flex p-3 overflow-hidden bg-white shadow-lg rounded-2xl hover:shadow-xl'>
            <Image
              src={Facebook}
              alt='Facebook logo'
              width={72}
              height={72}
              className='rounded-2xl'
              draggable='false'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
