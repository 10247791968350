import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

interface MotionInViewProps {
  children: ReactNode
}

export const MotionInView: FC<MotionInViewProps> = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <MotionDiv
      ref={ref}
      variants={{
        hidden: { y: 25, opacity: 0 },
        visible: { y: 0, opacity: 1 },
      }}
      animate={inView ? 'visible' : 'hidden'}
      transition={{ duration: 0.25, type: 'tween', ease: 'easeInOut' }}
    >
      {children}
    </MotionDiv>
  )
}

const MotionDiv = motion.div
