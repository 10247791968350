import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Image from 'next/image'
import clsx from 'clsx'
import { Button } from 'components'
import MegaPhone from 'public/img/megaphone.gif'
import Emoji from 'public/img/love-emoji.png'

interface CommunityProps {
  discordLink: string
  background?: 'white' | 'gray'
}

export const Community: FC<CommunityProps> = ({ discordLink, background = 'white' }) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={clsx(
        background === 'white' ? 'white' : 'bg-[#F9F9F9]',
        'py-24 overflow-hidden 2xl:px-4'
      )}
    >
      <div className='bg-[#5956E9] w-auto rounded-3xl container mx-4 relative sm:mx-auto'>
        <div className='bg-left bg-no-repeat bg-auto bg-community-pattern rounded-3xl md:bg-center md:bg-contain'>
          <div className='absolute -translate-x-1/2 translate-y-1/2 md:translate-y-0 md:translate-x-0 -top-36 sm:-top-48 left-1/2 md:top-0 md:-left-20'>
            <div className='w-48 h-48 lg:w-72 lg:h-72'>
              <Image
                src={MegaPhone}
                alt='animated megaphone'
                height='100%'
                width='100%'
                objectFit='contain'
                layout='responsive'
                draggable={false}
              />
            </div>
          </div>
          <div className='flex flex-col items-center px-4 pb-4 space-y-12 sm:pb-8 md:py-20 md:space-y-16'>
            <div className='w-full text-center mt-36 md:w-2/3 md:mt-0 xl:w-1/2'>
              <h2 className='text-3xl text-white sm:text-5xl lg:text-6xl'>
                {t('community-centered')}
              </h2>
            </div>
            <div className='flex flex-col items-center space-y-8 md:space-x-10 md:flex-row md:space-y-0 lg:space-x-32'>
              <ul className='font-medium text-gray-200'>
                <li>&gt; {t('one-of-first-testers')}</li>
                <li>&gt; {t('give-suggestions')}</li>
                <li>&gt; {t('receive-benefits')}</li>
                <li>&gt; {t('vote-for-features')}</li>
              </ul>
              <Link href={discordLink}>
                <Button variant='orange' aria-label='Open Discord invite'>
                  {t('join-our-discord-community')}
                </Button>
              </Link>
            </div>
          </div>
          <div className='absolute hidden bottom-10 -right-20 lg:block'>
            <Image
              src={Emoji}
              alt='3d heart eyes emoji'
              height={200}
              width={200}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
