import { FC, useState, FormEvent } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import { useRouter } from 'next/router'
import { setCookie } from 'nookies'
import Ticker from 'react-ticker'
import * as animationData from 'public/animations/hero.json'
import { Button } from '../Button'
import { Input } from 'components'
import { config } from 'config/config'

interface HeroProps {
  rollingText: string
  discordLink: string
}

export const Hero: FC<HeroProps> = ({ rollingText, discordLink }) => {
  const { t } = useTranslation('index')
  const [showHint, setShowHint] = useState(false)
  const [error, setError] = useState(false)
  const [passphrase, setPassphrase] = useState('')
  const router = useRouter()

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (passphrase !== config.passphrase) {
      setError(true)
      return setShowHint(true)
    }

    setCookie(null, 'passphrase', config.passphrase, {
      maxAge: 24 * 60 * 60 * 365, // 1 year
      path: '/',
    })
    router.push('/auth/signin')
  }

  return (
    <div className='relative flex flex-col h-screen bg-center bg-no-repeat bg-cover 2xl:px-4 bg-hero-gradient'>
      <div className='container grid flex-1 w-auto h-screen grid-cols-4 mx-4 sm:mx-auto md:grid-rows-hero-md lg:grid-rows-hero md:grid-cols-12 gap-x-6'>
        <header className='mt-8 col-span-full'>
          <Image src='/logo.svg' height={40} width={118.39} title='Hypzz' alt='logo' />
        </header>
        <div className='lg:mb-14 md:my-24 lg:my-0 place-self-center md:place-self-end col-span-full lg:col-span-5'>
          <h1 className='text-3xl leading-[1.5] font-semibold md:text-5xl md:leading-[1.25] text-navy-900'>
            {t('hero-title-1')}
            <span className='relative inline-block mx-3 before:block before:inset-y-0 before:absolute before:-inset-x-1 before:-skew-y-1 before:bg-highlight-green'>
              <span className='relative'>{t('hero-title-2')}</span>
            </span>
            {t('hero-title-3')}
          </h1>
        </div>
        <div className='w-full row-start-4 md:mt-14 place-self-start md:row-start-3 md:col-span-6 lg:col-span-5 col-span-full'>
          <div className='relative inline-block'>
            <p className='mb-2 text-2xl font-bold md:mb-8 text-navy-900'>
              {t('join-our-beta')}
            </p>
            <div className='absolute -right-20 -top-6'>
              <Image
                src='/img/pointing-down.png'
                width={80}
                height={80}
                alt='emoji hand pointing down'
              />
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <label htmlFor='passphrase'>{t('enter-passphrase')}</label>
            <div className='flex flex-col mt-4 mb-2 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0'>
              <Input
                id='passphrase'
                name='passphrase'
                type='text'
                value={passphrase}
                onChange={(e) => setPassphrase(e.target.value)}
                onBlur={() => setError(false)}
                isInvalid={error}
              />
              <Button type='submit' aria-label='Join Beta'>
                {t('join')}
              </Button>
            </div>
            {showHint && (
              <p className='text-sm'>
                {t('need-a-hint')}{' '}
                <Link href={discordLink} passHref>
                  <a className='font-semibold text-indigo-600'>{t('join-our-discord')}</a>
                </Link>
              </p>
            )}
          </form>
        </div>
        <div className='row-span-1 p-4 md:p-0 lg:row-span-2 place-self-center md:place-self-start lg:place-self-center md:col-start-7 col-span-full md:col-span-6 lg:col-span-7'>
          <Lottie
            loop
            play
            animationData={animationData}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </div>
      </div>
      <div className='mt-4 mb-2 md:mt-0'>
        <Ticker speed={4} mode='chain'>
          {() => <p className='whitespace-nowrap text-navy-900'>{rollingText}</p>}
        </Ticker>
      </div>
    </div>
  )
}
