import { FC, InputHTMLAttributes } from 'react'
import clsx from 'clsx'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean
}

export const Input: FC<InputProps> = ({ className, isInvalid, ...props }) => {
  return (
    <div
      className={clsx(
        className,
        'relative group transition-[filter] duration-250 hover:brightness-105'
      )}
    >
      <span
        style={{
          background: isInvalid
            ? 'linear-gradient(to left, #F87171 0%,#FCA5A5 8%,#FCA5A5 92%,#F87171 100%)'
            : 'linear-gradient(to left, #9CA3AF 0%,#D1D5DB 8%,#D1D5DB 92%,#9CA3AF 100%)',
        }}
        className='absolute inset-x-0 h-[52px] rounded-xl shadow-md'
      />
      <input
        className='w-full h-[52px] px-4 py-2 text-lg -translate-y-1 border-transparent bg-gray-100 rounded-xl outline-offset-4 focus:border-gray-400 focus:bg-white focus:ring-0 active:-translate-y-0.5 placeholder:text-gray-400'
        aria-invalid={isInvalid}
        {...props}
      />
    </div>
  )
}
