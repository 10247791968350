import { FC, FormEvent, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Input } from 'components'
import clsx from 'clsx'

enum States {
  DEFAULT,
  SUCCESS,
  ALREADY_SUBSCRIBED,
  INVALID,
  ERROR,
}

export const Newsletter: FC = () => {
  const { t } = useTranslation('common')
  const [state, setState] = useState<States>(States.DEFAULT)
  const [email, setEmail] = useState('')

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    const res = await window.fetch('/api/newsletter', {
      method: 'POST',
      body: JSON.stringify({ email }),
    })
    const data = await res.json()

    if (!data.success) {
      return setState(res.status === 400 ? States.INVALID : States.ERROR)
    }

    setState(data.alreadyExists ? States.ALREADY_SUBSCRIBED : States.SUCCESS)
  }

  const message = useMemo(() => {
    switch (state) {
      case States.SUCCESS:
        return t('newsletter-success')
      case States.ALREADY_SUBSCRIBED:
        return t('newsletter-already-subscribed')
      case States.INVALID:
        return t('newsletter-invalid')
      case States.ERROR:
        return t('newsletter-error')
      default:
        return null
    }
  }, [t, state])

  return (
    <div className='bg-[#F3E5FF] 2xl:px-4'>
      <div className='container w-auto py-12 mx-4 sm:mx-auto'>
        <div className='flex flex-col items-center justify-between space-y-10 md:space-y-0 md:flex-row md:space-x-8 lg:space-x-0'>
          <h3 className='text-2xl font-semibold text-center md:text-left text-navy-900'>
            {t('join-our-newsletter')}
          </h3>
          <form
            onSubmit={onSubmit}
            className='flex flex-col w-full space-y-4 sm:w-auto sm:space-y-0 sm:space-x-4 sm:flex-row md:flex-col md:space-y-4 md:space-x-0 lg:flex-row lg:space-y-0 lg:space-x-4 lg:items-center'
          >
            <div className='relative'>
              <Input
                type='text'
                placeholder='george@chr-ge.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='md:w-96'
              />
              {message && (
                <span
                  className={clsx(
                    state > 2 ? 'text-red-500' : 'text-green-500',
                    'sm:absolute pt-2 text-sm'
                  )}
                >
                  {message}
                </span>
              )}
            </div>
            <Button variant='orange' aria-label={t('subscribe')} type='submit'>
              {t('subscribe')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}
