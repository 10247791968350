import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import { MotionInView } from 'components'

export const Collaboration: FC = () => {
  const { t } = useTranslation('index')
  const [animationData, setAnimationData] = useState<object>()

  useEffect(() => {
    import('public/animations/hands.json').then(setAnimationData)
  }, [])

  return (
    <div className='bg-[#FDFAFF] py-28 2xl:px-4'>
      <div className='container grid w-auto grid-cols-4 mx-4 sm:mx-auto md:grid-cols-12 gap-x-6 place-items-center'>
        <div className='cursor-default pointer-events-none select-none col-span-full md:col-span-6'>
          {animationData && (
            <Lottie
              loop
              play
              animationData={animationData}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            />
          )}
        </div>
        <div className='mt-8 space-y-12 md:mt-0 col-span-full md:col-start-8 md:col-span-5'>
          <MotionInView>
            <h2 className='break-words text-3xl leading-[1.5] font-semibold md:leading-[1.3] md:text-5xl text-navy-900'>
              {t('collaboration-title-1')}
              <span className='relative inline-block mx-3 before:block before:absolute before:-inset-x-1 before:inset-y-1 before:skew-y-1 before:bg-highlight-green'>
                <span className='relative'>{t('collaboration-title-2')}</span>
              </span>
              {t('collaboration-title-3')}{' '}
              <span className='relative inline-block mx-3 before:block before:absolute before:-inset-x-1 before:inset-y-1 before:-skew-y-1 before:bg-highlight-green'>
                <span className='relative'>{t('collaboration-title-4')}</span>
              </span>
              {t('collaboration-title-5')}{' '}
              <span className='relative inline-block mx-3 before:block before:absolute before:-inset-x-1 before:inset-y-1 before:bg-highlight-green'>
                <span className='relative'>{t('collaboration-title-6')}</span>
              </span>
            </h2>
          </MotionInView>
          <MotionInView>
            <p className='font-medium text-gray-500'>{t('collaboration-text')}</p>
          </MotionInView>
        </div>
      </div>
    </div>
  )
}
