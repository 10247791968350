import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FaCheckCircle } from 'react-icons/fa'

const LANGUAGES: Record<string, string> = {
  en: 'English',
  pt: 'Português',
  fr: 'Français',
}

interface FooterProps {
  copyright: string
  contactEmail: string
  socialLinks: { label: string; href: string }[]
}

export const Footer: FC<FooterProps> = ({ copyright, contactEmail, socialLinks }) => {
  const { t, i18n } = useTranslation('footer')
  const router = useRouter()
  const { pathname, asPath, query } = router

  return (
    <footer className='bg-[#F9F9F9] pt-24 overflow-hidden 2xl:px-4'>
      <div className='container w-auto mx-4 sm:mx-auto'>
        <div className='flex flex-col divide-y'>
          <div className='flex flex-col justify-between w-full pb-10 space-y-12 md:pb-20 md:flex-row md:space-y-0'>
            <div className='flex flex-col items-start flex-1 space-y-4'>
              <Image src='/logo.svg' height={48} width={142} alt='logo' />
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <button
                    className='mt-4 group'
                    aria-label={t('change-language')}
                    type='button'
                  >
                    {LANGUAGES[i18n.language]}
                    <span className='group-hover:pl-1'>-</span>
                  </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                  sideOffset={4}
                  align='start'
                  className='py-2 bg-[#FDFAFF] rounded-md border border-navy-900 w-48'
                >
                  <DropdownMenu.Arrow offset={16} />
                  {Object.keys(LANGUAGES).map((lang) => (
                    <DropdownMenu.Item
                      key={lang}
                      className='flex items-center justify-between px-4 py-1 cursor-pointer select-none text-navy-900 hover:bg-purple-100'
                      onSelect={() =>
                        router.push({ pathname, query }, asPath, { locale: lang })
                      }
                    >
                      {LANGUAGES[lang]}
                      {i18n.language === lang && <FaCheckCircle />}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
            <div className='flex justify-between flex-1 space-x-2'>
              <div className='space-y-6'>
                <p className='font-medium text-gray-400'>{t('company')}</p>
                <div className='text-[#292930] flex flex-col gap-y-2'>
                  <Link href='/blog' passHref>
                    <a className='hover:underline'>{t('blog')}</a>
                  </Link>
                  {/* <Link href='/' passHref>
                    <a className='hover:underline'>{t('press')}</a>
                  </Link> */}
                </div>
              </div>
              <div className='space-y-6'>
                <p className='font-medium text-gray-400'>{t('support')}</p>
                <div className='text-[#292930] flex flex-col gap-y-2'>
                  <Link href={`mailto:${contactEmail}`} passHref>
                    <a className='hover:underline'>{t('contact-us')}</a>
                  </Link>
                  <Link href='/privacy-policy' passHref>
                    <a className='hover:underline'>{t('privacy-policy')}</a>
                  </Link>
                  <Link href='/terms-of-service' passHref>
                    <a className='hover:underline'>{t('terms')}</a>
                  </Link>
                </div>
              </div>
              <div className='space-y-6'>
                <p className='font-medium text-gray-400'>{t('community')}</p>
                <div className='text-[#292930] flex flex-col gap-y-2'>
                  {socialLinks.map((link, i) => (
                    <Link key={i} href={link.href} passHref>
                      <a
                        className='hover:underline'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {link.label}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className='flex-1 hidden lg:block'></div>
          </div>
          <div className='relative flex justify-between w-full py-6 text-sm text-gray-500'>
            <p>{copyright}</p>
            <div className='absolute hidden -bottom-20 right-1/4 sm:block'>
              <Image
                src='/img/victory.png'
                width={200}
                height={200}
                alt='victory hand sign'
                draggable='false'
                className='transition-transform ease-out hover:-translate-y-1 hover:will-change-transform'
              />
            </div>
            <Link href={asPath} passHref>
              <a className='hover:text-gray-600'>↑ Back to top</a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
