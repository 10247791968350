import { FC, ReactNode, useMemo } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { urlFor } from 'clients/sanity'
import { Footer } from 'components/ui'
import { config } from 'config/config'
import type { WebsiteSettings } from 'utils/types/sanity/generated-schema-types'
import { CustomImage } from 'utils/types/sanity/custom-image'

interface MainLayoutProps {
  title?: string
  ogTitle?: string
  description?: string
  ogImage?: CustomImage
  path: string
  settings: WebsiteSettings
  preview: boolean
  children: ReactNode
}

export const MainLayout: FC<MainLayoutProps> = ({
  title,
  ogTitle,
  description,
  path,
  ogImage,
  settings,
  children,
}) => {
  const { i18n } = useTranslation()
  const imageUrl = ogImage
    ? urlFor(ogImage.asset).width(1200).height(630).url() || ''
    : ''

  const socialLinks = useMemo(
    () => [
      ...(settings.discord ? [{ label: 'Discord', href: settings.discord || '' }] : []),
      ...(settings.instagram
        ? [{ label: 'Instagram', href: settings.instagram || '' }]
        : []),
      ...(settings.tiktok ? [{ label: 'TikTok', href: settings.tiktok || '' }] : []),
      ...(settings.reddit ? [{ label: 'Reddit', href: settings.reddit || '' }] : []),
      ...(settings.twitter ? [{ label: 'Twitter', href: settings.twitter || '' }] : []),
    ],
    [
      settings.discord,
      settings.instagram,
      settings.tiktok,
      settings.reddit,
      settings.twitter,
    ]
  )

  const basePath = `${config.host}${
    i18n.language !== config.defaultLocale ? `/${i18n.language}` : ''
  }`

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
        <title>{title || settings.title || 'Hypzz'}</title>
        <meta name='description' content={description || settings.description} />
        <link rel='canonical' href={config.host + path} />
        <meta property='og:title' content={ogTitle || title || settings.title} />
        <meta property='og:description' content={description || settings.description} />
        <meta property='og:url' content={basePath + path} />
        <meta property='og:type' content='website' />
        {ogImage && (
          <>
            <meta property='og:image' content={imageUrl} />
            <meta property='og:image:width' content='1200' />
            <meta property='og:image:height' content='630' />
            {ogImage.alt && <meta property='og:image:alt' content={ogImage.alt} />}
            <meta name='twitter:image' content={imageUrl} />
            {ogImage.alt && <meta name='twitter:image:alt' content={ogImage.alt} />}
          </>
        )}
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={ogTitle || title || settings.title} />
        <meta name='twitter:description' content={description || settings.description} />
      </Head>
      <main>{children}</main>
      <Footer
        copyright={settings.copyright || ''}
        contactEmail={settings.email || ''}
        socialLinks={socialLinks}
      />
    </>
  )
}
